import Chart from 'chart.js';
import palette from './palette';
import 'milligram/dist/milligram.min.css';
import 'normalize.css/normalize.css';

let dates = [];
let years = [];
const ctx = document.getElementById('myChart');
const calId = document.getElementById('cal');
const cal = [];
const yearCalId = document.getElementById('yearcal');
const yearCal = [];
const ordered = {};
const count = [];
const labels = [];

const params = new URLSearchParams(document.location.search.slice(1));
const query = params.get('q');

const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

function addData(property, data) {
  labels.push(property);
  count.push(data[property].count);
}

function sortDate(data) {
  Object.keys(data).sort().forEach((key) => {
    dates.push(key.split('-').slice(0, 2).join('-'));
    ordered[key] = data[key];
    years.push(key.split('-').slice(0, 1).join('-'));
  });
  dates = [...new Set(dates)];
  years = [...new Set(years)];
}

function listDates() {
  /* eslint-disable no-plusplus */
  for (let index = 0; index < dates.length; index++) {
    const element = dates[index];
    cal.push(`<li><a href="/?q=${element}">${element}</a></li>`);
  }
  calId.innerHTML = cal.join('');
}

function listYears() {
  /* eslint-disable no-plusplus */
  const what = [];
  for (let index = 0; index < years.length; index++) {
    const element = years[index];
    what.push(years[index]);
    yearCal.push(`<li><a href="/?q=${element}">${element}</a></li>`);
  }
  yearCalId.innerHTML = yearCal.join('');
  return what;
}

fetch('https://scribble.fyi/36f336df-1efb-46a6-a8d5-9eaa70e2ff6e.txt')
  .then((response) => response.json())
  .then((data) => {
    sortDate(data);
    listDates();
    listYears();

    /* eslint-disable no-restricted-syntax */
    /* eslint-disable guard-for-in */
    if (query) {
      document.title = query ? `coffee counter - ${query}` : 'coffee counter';
      for (const property in ordered) {
        if (property.startsWith(query)) {
          addData(property, data);
        }
      }
    } else {
      for (const property in ordered) {
        addData(property, data);
      }
    }

    const dailyAverage = Math.round(average(count));
    const totals = count.reduce((a, b) => a + b);
    const monhtlyAverage = Math.round(totals / dates.length); // this doesn't look right

    document.getElementById('avg').innerText = dailyAverage;
    document.getElementById('total').innerText = totals;
    document.getElementById('monthlyAvg').innerText = monhtlyAverage;

    /* eslint-disable no-new */
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [{
          label: 'coffee consumption',
          data: count,
          backgroundColor: palette('tol-rainbow', count.length),
        }],
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      },
    });
  });
